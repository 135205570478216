.loginpage{
    height:100vh;
    overflow: hidden;
}
.loginpage::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../../../public/images/bg.png) no-repeat center;
    background-size: cover;
    filter: blur(12px);
    opacity:0.8;
    z-index: -1;
  }

.background{
    width: 430px;
    height: 520px;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}
.background .shape{
    height: 200px;
    width: 200px;
    position: absolute;
    border-radius: 50%;
}
.loginform{
    width:400px;
    background-color: rgba(0,0,0,0.6);
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    border-radius:10px;
    backdrop-filter: blur(20px);
    border: 2px solid rgba(0,0,0,0.1);
    box-shadow:0 0 10px rgba(0, 0, 0, .2);
    padding:20px 40px 40px 40px;
}
form.loginform *{    
    outline: none;
}
.loginform h5 {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    color: var(--WhiteColor);
    background: linear-gradient(to right, var(--RedColor), var(--PurpleColor));
    padding: 20px 0;
    margin: -22px -42px 0 -42px;
    border-radius: 10px 10px 0 0;
}

.loginform input{
    display: block;
    height: 50px;
    width: 100%;
    background-color:var(--WhiteColor);
    border-radius:25px;
    padding: 0 20px;
    margin-top:30px;
    font-size: 16px;  
    border: none;    
}
.loginform::placeholder{
    color:var(--BlackColor);
}
.loginform button{
    margin-top:30px;
    border: none;    
    width: 100%;
    padding:12px 0;
    font-size: 16px;    
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn.btn:disabled{
    background:var(--PurpleColor) !important;
    }
form.loginform button svg path{
    fill:var(--WhiteColor);
}
.formfield{
    position:relative;
}
.formfield span {
    position: absolute;
    right: 23px;
    top: 11px;
    cursor:pointer;
}
.formfield span svg{
    width:22px;
    height:22px;    
}
.formfield span svg path{
fill:var(--RedColor);
}
.errormessage {
    color: red;
    position: absolute;
    top: -4px;
    font-size: 14px;
    right: 20px;
}
/* Loader style */
.loader {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey */
    border-radius: 50%;
    border-top: 4px solid #fff; /* White */
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  .detailstable .loader {border: 4px solid rgb(255 0 0 / 59%);border-top: 4px solid #000;}
  
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  