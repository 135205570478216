.pageLeftspace {
    margin-top:20px;  
    padding:0 20px;      
}
.pageLeftspace .bg-danger{
    background-color:var(--RedColor) !important;
}
.pageLeftspace .bg-warning{
    background-color:var(--YellowColor) !important;
}
.pageLeftspace .bg-success{
    background-color:var(--GreenColor) !important;
}
.pageLeftspace .bg-info{
    background-color:var(--PurpleColor) !important;
}

.small-box {
    color:var(--WhiteColor);
    border-radius:.25rem;
    box-shadow:0 7px 16px 1px #b9b9b95e;
    display: block;
    margin-bottom: 20px;
    position: relative;
}
.small-box>.inner {
    padding: 10px;
}
.small-box h3 {
    font-size: 2.2rem;
    font-weight: 700;
    margin: 0 0 10px;
    padding: 0;
    white-space: nowrap;
}
.small-box h3 p{
    font-size:12px;
    color:var(--WhiteColor);    
}

.small-box p {
    font-size: 1rem;
}

.small-box .icon> svg {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: -webkit-transform .3s linear;
    transition: transform .3s linear;
    transition: transform .3s linear, -webkit-transform .3s linear;
}
.small-box .icon> svg {
    width: 70px;
    top: 20px;
}
.small-box .icon> svg rect,
.small-box .icon> svg path{
    fill: rgba(0, 0, 0, .15);
    z-index: 0;
}
.small-box>.small-box-footer {
    background-color: rgba(0, 0, 0, .1);
    color: rgba(255, 255, 255, .8);
    display: block;
    padding: 3px 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    z-index: 10;
}
.small-box-footer svg path{
    fill:var(--WhiteColor);
}
.bg-warning, .bg-warning>a {
    color: #1f2d3d !important;
}
.bg-warning>a svg path{
    fill: #1f2d3d !important;
}

.detailstable.transfaldetailstable{
    width:23%;
}
.detailstable.Inventorydata{
    width:41%;
}