@font-face {
    font-family: 'Avenir LT Pro';
    src: url('../fonts/AvenirLTPro-Medium.eot');
    src: url('../fonts/AvenirLTPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTPro-Medium.woff2') format('woff2'),
        url('../fonts/AvenirLTPro-Medium.woff') format('woff'),
        url('../fonts/AvenirLTPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Pro 55 Roman';
    src: url('../fonts/AvenirLTPro-Heavy.eot');
    src: url('../fonts/AvenirLTPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTPro-Heavy.woff2') format('woff2'),
        url('../fonts/AvenirLTPro-Heavy.woff') format('woff'),
        url('../fonts/AvenirLTPro-Heavy.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ITC Benguiat';
    src: local('ITC Benguiat Bold'), local('ITC-Benguiat-Bold'),
        url('../fonts/BenguiatStd-Bold.woff2') format('woff2'),
        url('../fonts/BenguiatStd-Bold.woff') format('woff'),
        url('../fonts/BenguiatStd-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

@font-face {
    font-family: 'M PLUS 1';
    src: url('../fonts/MPLUS1-Regular.eot');
    src: url('../fonts/MPLUS1-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MPLUS1-Regular.woff2') format('woff2'),
        url('../fonts/MPLUS1-Regular.woff') format('woff'),
        url('../fonts/MPLUS1-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Luckiest Guy';
    src: url('../fonts/LuckiestGuy-Regular.eot');
    src: url('../fonts/LuckiestGuy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/LuckiestGuy-Regular.woff2') format('woff2'),
        url('../fonts/LuckiestGuy-Regular.woff') format('woff'),
        url('../fonts/LuckiestGuy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('../fonts/Outfit-Regular.eot');
    src: url('../fonts/Outfit-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Outfit-Regular.woff2') format('woff2'),
        url('../fonts/Outfit-Regular.woff') format('woff'),
        url('../fonts/Outfit-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('../fonts/Outfit-SemiBold.eot');
    src: url('../fonts/Outfit-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Outfit-SemiBold.woff2') format('woff2'),
        url('../fonts/Outfit-SemiBold.woff') format('woff'),
        url('../fonts/Outfit-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('../fonts/Outfit-Medium.eot');
    src: url('../fonts/Outfit-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Outfit-Medium.woff2') format('woff2'),
        url('../fonts/Outfit-Medium.woff') format('woff'),
        url('../fonts/Outfit-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('../fonts/Outfit-Light.eot');
    src: url('../fonts/Outfit-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Outfit-Light.woff2') format('woff2'),
        url('../fonts/Outfit-Light.woff') format('woff'),
        url('../fonts/Outfit-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Outfit';
    src: url('../fonts/Outfit-Bold.eot');
    src: url('../fonts/Outfit-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Outfit-Bold.woff2') format('woff2'),
        url('../fonts/Outfit-Bold.woff') format('woff'),
        url('../fonts/Outfit-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}