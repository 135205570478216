.editeandremove {
    position: absolute;
    background: var(--WhiteColor);
    padding:5px 10px;
    top: 10px;
    right: 22px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 #00000052;
    z-index:1;
}
.editeandremove svg{
    width:15px;
    height:15px;
}
.tablewithpaginamtion {
    width: 100%;
}
.StockQuantity .detailstable{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height:200px;
}
.tabletitle{
    width:60%;
}
.detailstable {
    box-shadow: 0 0 10px 2px #0000001f;
    border-radius: 20px;
    padding:15px;
    width:32.5%;
}
.statusbtn .btn {
    padding: 3px 5px;    
    font-size:12px;
}
.detailstable h5{
    font-size:16px;
    margin-bottom:10px;
}
.detailstable .table{background:var(--WhiteColor); --bs-table-bg: #fff; margin-bottom:0;}
.detailstable .form-check-input{margin-right:4px; margin-top:0;}
.detailstable .table th, .table tr td{
    font-size:12px;    
    line-height:14px;    
    border: solid 1px #ddd;
    padding: 4px;
}
.detailstable thead .action{
    width:30px;
    text-align:center;
}
.detailstable tbody tr .action{
    text-align:center;
}
.detailstable tbody tr .active svg path{
fill:var(--PurpleColor);
}
.tableheader {
    margin-bottom: 20px;
    background: var(--GrayColor);
    padding: 15px;
    border-radius: 10px;
}
.search-bar {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: end;
    align-items:center;
    gap:10px;
}

.dotsthree svg path{
    fill:#cfcfcf;
}
.pagination{
    gap:5px;
    margin-top:11px;
}
.pagination li a {
    width:25px;
    height:25px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--WhiteColor);
    color:var(--BlueColor);
    font-size:12px;
}
.pagination li a.pagination-link.active{
background:var(--PurpleColor);
color:var(--WhiteColor);
}
.pagination-link.disabled{
pointer-events:none; opacity:0.4;
}
.form-control{
    border-radius:25px !important;
}
  .search-bar .form-control {
    height: 41px;
    padding-right:35px;    
}
.search-bar.inputsearch .form-control{
    padding-right:10px;
}
.search-bar .btn {
    padding-top: 8px;
    padding-bottom: 8px;
}
.addbutton.btn{
    align-items: center;
    display: inline-flex;
    margin-right:10px;
}
.search-bar .btn svg path,
.addbutton svg path{
    fill:var(--WhiteColor);
}

.transfaldetailstable .table th {
    width: 147px;
}

.transfaldetailstable .table tr:nth-child(even) th{
    background: #fff;
}

.transfaldetailstable .table tr:nth-child(odd) th{    
    background: #f2f2f2;
}
.icon-view.active svg path{
    fill:var(--RedColor);
}