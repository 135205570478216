.rightside .form-control{
    border-radius:25px;
}
.search-status,
.selectoption{
    position:relative;
    width:50%;
}
.selectoption span {
    position: absolute;
    top:9px;
    right: 14px;
    transform: rotate(-90deg);
    pointer-events:none;
}
.rightside {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
}
.search-status span {
    position: absolute;
    top: 7px;
    right: 11px;        
}
.tabletitle .search-status {
    width: 90%;
}
.tabletitle .search-status span {
    top: 2px;
}
.tabletitle .search-status span  svg{
    width:18px;
    height:18px;
}
.tabletitle .search-status .form-control{
padding-right:35px;
font-size:12px;
}