.header-top{
    background:var(--PurpleColor);
    padding:10px 20px;    
}
.header-top h2{ 
color:var(--WhiteColor);    
}
.logoinner img{
width:100px;
}

.page-title svg path{
fill:var(--WhiteColor);
}
.user svg{
    width:28px;
    height:28px;
    fill:var(--WhiteColor);
}
.user svg path{
fill:var(--WhiteColor);
}
ul.user-menu {
    position: absolute;
    right:20px;
    top: 58px;
    background: var(--WhiteColor);
    padding: 20px;
    z-index: 9;
    box-shadow: inset 0px 0px 10px 10px #645f5f24;
    font-size: 14px;
    width: 23%;
    border-radius: 0 0 10px 10px;
}
ul.user-menu li a,
ul.user-menu li {
color:var(--BlackColor);
}
ul.user-menu li {
    margin-bottom: 4px;
    border-bottom: solid 1px #dddddd9e;
    padding-bottom: 4px;
}
ul.user-menu li:last-child{margin-bottom:0;}
ul.user-menu li svg{
    width:20px;
    height:20px;
    margin-right:10px;
}
ul.user-menu li svg path{
fill:var(--RedColor)
}
button.logout {
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
}