html {
    scroll-behavior: smooth;
  }
:root {
    --BoxBg: linear-gradient(111deg, rgba(255, 255, 255, 0.25) -4.87%, rgba(255, 255, 255, 0.17) 103.95%);               
    --BackdropFilter:blur(25px);
    --BorderRadius: 20px;
    --BorderRadius25:25px;
    --BorderRadius50:50px;    
    --BorderRadius100:100px;
    --border: solid 1px #fff;
    --boxShadow:0px 25px 35px 0px rgba(51, 51, 51, 0.10);                
    --GoldColor: #FFC032;
    --RedColor: #EC128E;
    --PurpleColor: #28ACE2;
    --YellowColor: #FDCF28;
    --GreenColor: #A6CF42;    
    --OrangeDarkColor:#FF9900;
    --GrayDarkColor:#9C9C9C;
    --BlueColor: #2A65AD;       
    --GrayColor:#f3f3f3;
    --BlueDarkColor: #3069B1;
    --bluelight:#2365AD;
    --BlackColor: #000;
    --WhiteColor: #fff;    
}
        
body {
    font-size: 16px;
    font-family: 'Outfit', Arial, sans-serif;
    line-height: 24px;
    color: var(--BlackColor);
    font-weight: 400;    
}

.box-default{
    background: var(--BoxBg);
    border:var(--border);
    box-shadow:var(--boxShadow);
    backdrop-filter:var(--BackdropFilter);
}

figure{margin-bottom:0;}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

:focus-visible,
:focus{
    outline: none;
}
ol{margin:0;padding:0;}
h1,
h2,
h3,
h4,
h5,
h6,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.pt0 {
    padding-top: 0;
}

.pb {
    padding-bottom: 0;
}
.pb200 {padding-bottom:200px;}
.pt200{padding-top:200px;}
.pt100 {
    padding-top: 100px;
}
.mt100{margin-top:100px;}
.mb100{margin-bottom:100px;}
.mt,
.mb{margin:0;}
.pb100 {
    padding-bottom: 100px;
}

.pt50 {
    padding-top: 50px;
}

.pb50 {
    padding-bottom: 50px;
}
.pt115{padding-top:115px;}
.container-fluid {
    max-width: 1920px;
}

.container {
    max-width: 1240px;
}


.titletwo {
    margin-bottom: 50px;
}
.title1{
    /* font-family: 'ITC Benguiat'; */
    font-weight:bold;
    font-size:50px;
    line-height:50px;
    color:var(--RedColor);
}
.titletwo h2 {
    font-size: 50px;
    /* font-family: 'ITC Benguiat'; */
    font-weight: 700;
    line-height: normal;

}

.titletwo h2 span {
    color: var(--RedColor);
}

.title3 {
    color: var(--YellowColor);
    font-size: 64px;
    /* font-family: 'Luckiest Guy'; */
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active{
    color:currentColor;
}
.btn {
    color: var(--WhiteColor);
    padding:10px 30px;
    border-radius:25px;
    transition: all 0.5s;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    border:none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn label{position: relative; z-index: 1;color:var(--WhiteColor) !important;cursor: pointer;}
.btn svg {
    margin-right:5px;
    transition: all 0.5s;    
}
.btn-danger{
    background: var(--RedColor);
}
.btn-dark{
    background:var(--BlackColor);
}
.btn-purpal{
    background:var(--PurpleColor);
}
.btn-purpal:focus-visible,
.btn-purpal:active,
.btn-purpal:hover {
    color:var(--WhiteColor);
    background-color:#0b8dc0;
    border:none;
}

@media screen and (max-width:1199px) {
.container{padding:0 20px;}
.row{margin:0 -20px;}
    
}
@media screen and (max-width:992px) {
    body{font-size:14px; line-height:20px;}
    .btn {
        padding: 10px 20px;
        font-size: 14px;
    }
    :root {
    --BorderRadius50:20px;    
    --BorderRadius100:50px;
    } 
    .titletwo {
        margin-bottom: 30px;
    }
    .titletwo h2{font-size:35px;}    
    .pt115,
    .pt100 {
        padding-top:50px;
    }
        .pb100 {
        padding-bottom:50px;
    }
    .pt50 {
        padding-top:25px;
    }
    .pb50 {
        padding-bottom:25px;
    }    
    .mt100{margin-top:50px;}
    .mb100{margin-bottom:50px;}
    
}

@media screen and (max-width:767px) {
    .btn {
        padding: 8px 10px;
        font-size: 12px;
    }
    .btn svg {
        width:20px;
        margin-left:2px;
    }
    .titletwo h2 {
        font-size: 25px;
    }
    .titletwo {
        margin-bottom: 20px;
    }
    .title1{
        font-size: 32px;
        line-height: 35px;
    }
}
